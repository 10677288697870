import About from '../About';
import Services from '../Services';
import Contact from '../Contact';

function Main() {
  return (
    <>
      <main>
        <Services />
        <About />
        <Contact />
      </main>
    </>
  );
}
export default Main;
