// 在React组件内部（Header.js或Header.jsx）
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import video from '../../assets/img/video.mp4';
import './index.css';

function Header() {
  const [t] = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [isLoginMode, setIsLoginMode] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // 新增的状态

  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const openModal = (loginMode = false) => {
    setIsModalOpen(true);
    setIsLoginMode(loginMode);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    // 重置表单状态
    setEmail('');
    setEmailError('');
    setPassword('');
    setPasswordError('');
    setConfirmPassword('');
    setConfirmPasswordError('');
    setIsLoginMode(false);
  };

  const toggleLoginMode = () => {
    // 清空所有状态
    setEmail('');
    setEmailError('');
    setPassword('');
    setPasswordError('');
    setConfirmPassword('');
    setConfirmPasswordError(''); // 重置 confirmPassword 的错误状态

    // 切换登录和注册模式
    setIsLoginMode((prevMode) => !prevMode);
  };



  const validateEmail = () => {
    const isValidEmail = emailPattern.test(email);
    setEmailError(isValidEmail ? '' : '无效的邮箱');
  };

  const validatePassword = () => {
    setPasswordError(password.length < 6 ? '密码至少需要6位' : '');
  };

  const validateConfirmPassword = () => {
    if (confirmPassword) {
      setConfirmPasswordError(password !== confirmPassword ? '两次输入的密码不一致' : '');
    }
  };

  const handleSignUp = async () => {
    validateEmail();
    validatePassword();
    validateConfirmPassword();

    if (emailError || passwordError || confirmPasswordError) {
      return;
    }

    try {
      setPasswordError('');
      const response = await fetch('https://whsghd.com/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      });

      if (response.ok) {
        const data = await response.text();

        if (data === '注册成功') {
          toast.success('注册成功', { position: toast.POSITION.TOP_RIGHT });
          closeModal();
        } else {
          toast.error(data, { position: toast.POSITION.TOP_RIGHT });
        }
      } else {
        console.error('注册失败');
      }
    } catch (error) {
      console.error('发生错误:', error.message);
    }
  };

  const handleLogin = async () => {
    validateEmail();
    validatePassword();

    if (emailError || passwordError) {
      return;
    }

    try {
      const response = await fetch('https://whsghd.com/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      });

      if (response.ok) {
        const data = await response.text();

        if (data === '登录成功') {
          toast.success('登录成功', { position: toast.POSITION.TOP_RIGHT });
          setIsLoggedIn(true); // 设置登录状态
          closeModal();
        } else {
          toast.error(data, { position: toast.POSITION.TOP_RIGHT });
        }
      } else {
        console.error('登录失败');
      }
    } catch (error) {
      console.error('发生错误:', error.message);
    }
  };

  return (
    <header>
      <video src={video} loop autoPlay muted></video>
      <h1>{t('homeName')}</h1>
      <div className={`row ${isModalOpen ? 'modal-open' : ''}`}>
        <button className="btn modal-trigger-button" onClick={() => openModal(false)}>
          {t('signUp')}
        </button>
        <button className="btn modal-trigger-button" onClick={() => openModal(true)}>
          {t('logIn')}
        </button>
      </div>

      <div className="headerbg"></div>

      {isModalOpen && (
        <div className="modal-container">
          <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Auth Modal"
            ariaHideApp={false}
            style={{
              overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                zIndex: 2,
              },
              content: {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '35%',
                height: '70%',
                textAlign: 'center',
              },
            }}
          >
            <h2 style={{ marginBottom: '20px', color: '#333' }}>
              {isLoginMode ? '登录' : '注册'}
            </h2>
            <div className="form-group" style={{ height: '110px' }}>
              <label htmlFor="email">邮箱：</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={validateEmail}
                autoComplete="off"
              />
              {emailError && (
                <p style={{ color: 'red', marginTop: '-10px' }}>{emailError}</p>
              )}
            </div>

            <div className="form-group" style={{ height: '110px' }}>
              <label htmlFor="password">密码：</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  validateConfirmPassword();
                }}
                onBlur={validatePassword}
                autoComplete="new-password"
              />
              {passwordError && (
                <p style={{ color: 'red', marginTop: '-10px' }}>{passwordError}</p>
              )}
            </div>

            {!isLoginMode && (
              <div className="form-group" style={{ height: '110px' }}>
                <label htmlFor="confirmPassword">确认密码：</label>
                <input
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  onBlur={validateConfirmPassword}
                  autoComplete="new-password"
                />
                {confirmPasswordError && (
                  <p style={{ color: 'red', marginTop: '-10px' }}>
                    {confirmPasswordError}
                  </p>
                )}
              </div>
            )}

            <div
              className="form-group"
              style={{
                position: 'fixed',
                bottom: '20px',
                left: '50%',
                transform: 'translateX(-50%)',
                width: '100%',
              }}
            >
              <button id="submitBtn" onClick={isLoginMode ? handleLogin : handleSignUp} style={{ marginRight: '30px' }}>
                {isLoginMode ? '登录' : '注册'}
              </button>
              <button id="cancelBtn" onClick={closeModal}>
                关闭
              </button>
              <p
                style={{ cursor: 'pointer', color: 'blue', marginTop: '10px' }}
                onClick={toggleLoginMode}
              >
                {isLoginMode ? '切换到注册' : '切换到登录'}
              </p>
            </div>
          </Modal>
        </div>
      )}
    </header>
  );
}

export default Header;
