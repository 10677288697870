import "./index.css";
import { Carousel } from "antd";

function Card(props) {
  return (
    <div className="card">
      <br />
      <div className="text-center">
        <h3 className="card-title">{props.title}</h3>
      </div>
      <div className="p-3">
        <p className="card-text">{props.text}</p>
      </div>
      <div className="banner">
        <Carousel autoplay>
          {props.bannerList.map((item, i) => {
            return <img className="img" alt="" src={item} key={i} />;
          })}
        </Carousel>
      </div>
    </div>
  );
}
export default Card;
