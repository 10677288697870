import Card from "../Card";
import { useTranslation } from "react-i18next";
import image1 from "../../assets/img/image1.png";
import image2 from "../../assets/img/image2.png";
import image3 from "../../assets/img/image3.jfif";
import image4 from "../../assets/img/image4.jfif";
import image5 from "../../assets/img/image5.jfif";

function Services() {
  const [t] = useTranslation();
  const banner1 = [image3, image4];
  const banner2 = [image5, image5];
  const banner3 = [image1, image2];
  return (
    <div className="container services">
      <h2 className="main-title text-center">{t("product")}</h2>
      <Card
        title={t("product1")}
        img="card1.png"
        text={t("proText1")}
        bannerList={banner1}
      />
      <Card
        title={t("product2")}
        img="card2.png"
        text={t("proText2")}
        bannerList={banner2}
      />
      <Card
        title={t("product3")}
        img="card3.png"
        text={t("proText3")}
        bannerList={banner3}
      />
    </div>
  );
}
export default Services;
