import { Link, animateScroll as scroll } from "react-scroll";
import { useTranslation } from "react-i18next";
import "./Footer.css";

function Footer() {
  const [t] = useTranslation();

  return (
    <footer className="footer-container">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="footer-section">
              <p className="footer-title">{t("ImportantLink")}</p>
              <ul className="footer-links">
                <li>
                  <Link spy={true} smooth={true} duration={1000} to="headerbg">
                    {t("Home")}
                  </Link>
                </li>
                <li>
                  <Link spy={true} smooth={true} duration={1000} to="services">
                    {t("product")}
                  </Link>
                </li>
                <li>
                  <Link spy={true} smooth={true} duration={1000} to="about-scroll">
                    {t("ABOUT")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="footer-section">
              <p className="footer-title">{t("CONTACT")}</p>
              <ul className="footer-links">
                <li>
                  <Link to="#">tadpolesai@gmail.com</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        Copyright © 2023{" "}
        &nbsp;
        {/*<strong>*/}
          <a className="footer-link" href="https://www.whsghd.com" target="_blank" rel="noreferrer noopener">
            武汉视感互动科技有限公司
          </a>
        {/*</strong>*/}
        &nbsp;
        {/*<strong>*/}
          <a className="footer-link" href="https://www.whsghd.com" target="_blank" rel="noreferrer noopener">
            whsghd.com
          </a>
        {/*</strong>{" "}*/}
        &nbsp;
        All Rights Reserved. 备案号：
        <a target="_blank" rel="noreferrer noopener" className="footer-link" href="https://beian.miit.gov.cn/">
          鄂ICP备2023024631号-1
        </a>
      </div>
      <button onClick={() => scroll.scrollToTop(2500)} className="gotop">
        <i className="fas fa-level-up-alt"></i>
      </button>
    </footer>
  );
}

export default Footer;
