import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import "./index.css";
import ChangeLang from "../ChangeLang/index";

function Navbar() {
  document.addEventListener("scroll", function (e) {
    if (window.screen.width < 768 && window.scrollY > 690) {
      const gotop = document.querySelector(".gotop");
      gotop.classList.add("display");

      const nav = document.querySelector(".navbar");

      nav.classList.add("navopened");
    } else if (window.screen.width > 768 && window.scrollY > 220) {
      const gotop = document.querySelector(".gotop");
      gotop.classList.add("display");

      const nav = document.querySelector(".navbar");

      nav.classList.add("navopened");
    } else {
      const nav = document.querySelector(".navbar");
      const gotop = document.querySelector(".gotop");
      gotop.classList.remove("display");
      nav.classList.remove("navopened");
    }
  });
  function openBar() {
    const bar = document.querySelector(".bar");

    bar.classList.toggle("opened");
  }
  const [t] = useTranslation();

  return (
    <>
      <nav className="navbar">
        <div className="container">
          <div className="row">
            <h1 className="logo">
              <Link
                spy={true}
                smooth={true}
                duration={1000}
                to="headerbg"
                style={{ cursor: "pointer" }}
              >
                {t("homeName")}
              </Link>
            </h1>
            <ul className="bar">
              <li>
                <Link
                  onClick={openBar}
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  duration={1000}
                  to="headerbg"
                >
                  {t("home")}
                </Link>
              </li>
              <li>
                <Link
                  onClick={openBar}
                  activeClass="active"
                  to="services"
                  spy={true}
                  smooth={true}
                  duration={1000}
                >
                  {t("product")}
                </Link>
              </li>
              <li>
                <Link
                  onClick={openBar}
                  to="about-scroll"
                  spy={true}
                  smooth={true}
                  duration={1000}
                  activeClass="active"
                >
                  {t("about")}
                </Link>
              </li>
              <li>
                <Link
                  onClick={openBar}
                  to="contact"
                  spy={true}
                  smooth={true}
                  duration={1000}
                  activeClass="active"
                >
                  {t("contact")}
                </Link>
              </li>
            </ul>
            <div className="button" onClick={openBar}>
              <div className="burger"></div>
              <div className="burger"></div>
              <div className="burger"></div>
            </div>
          </div>
        </div>
        <ChangeLang></ChangeLang>
      </nav>
    </>
  );
}
export default Navbar;
// export default withTranslation()(Navbar)
