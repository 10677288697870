import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./ChangeLang.css";

function ChangeLang() {
  const [localLang, setLocalLang] = useState(localStorage.getItem("lang"));

  if (!localLang) {
    const browserLanguage = navigator.language || navigator.userLanguage;
    setLocalLang(browserLanguage === "zh-CN" ? "zh-CN" : "en-US");
  }
// eslint-disable-next-line no-unused-vars
  const [t, i18n] = useTranslation();

  const toggleI18n = (e) => {
    const locale = e.target.value === "zh-CN" ? "zh-CN" : "en-US";
    localStorage.setItem("lang", locale);
    setLocalLang(locale);
    i18n.changeLanguage(locale);
  };

  return (
    <div>
      <div className="language-selector">
        {/*<label htmlFor="languageSelect" className="languageLabel">language：</label>*/}
        <select id="languageSelect" className="change-lang" value={localLang} name={localLang} onChange={toggleI18n}>
          <option value="zh-CN">简体中文</option>
          <option value="en-US">English</option>
        </select>
      </div>
    </div>
  );
}

export default ChangeLang;
