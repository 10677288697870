import React, { useState, useRef, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";
import "./index.css";

function Contact() {
  const [t] = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const containerRef = useRef(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const clearForm = () => {
    setFormData({
      name: '',
      phone: '',
      email: '',
      message: '',
    });
  };

  const apiUrl = 'https://whsghd.com/express';

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.text();

        if (data === '发送成功') {
          toast.success('发送成功', { position: toast.POSITION.TOP_RIGHT });
          clearForm();
          setFormSubmitted(true);
        } else {
          toast.error(data, { position: toast.POSITION.TOP_RIGHT});
        }
      } else {
        throw new Error('网络错误');
      }
    } catch (error) {
      console.error('发生错误:', error);
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      const containerHeight = containerRef.current.clientHeight;
      containerRef.current.style.height = `${containerHeight}px`;
    }
  }, []);

  const notificationProps = useSpring({
    opacity: formSubmitted ? 1 : 0,
    marginTop: formSubmitted ? 0 : -100,
    config: {
      duration: 500,
    },
  });

  return (
    <div className="container contact" ref={containerRef}>
      <h2 className="main-title text-center">{t('CONTACT')}</h2>
      {formSubmitted ? (
        <animated.div style={notificationProps} className="contact-notification">
          <p className="notification-text">{t('SentSuccessfully')}</p>
        </animated.div>
      ) : (
        <form action="/express/save-contact" method="post" onSubmit={handleSubmit}>
          <div className="col-md-12">
            <div className="row">
              {['name', 'phone', 'email'].map((field) => (
                <div key={field} className="col-md-4 mb-1">
                  <input
                    name={field}
                    placeholder={field === 'name' ? t('Name') : field === 'phone' ? t('Phone') : t('Email')}
                    className="contact-input"
                    value={formData[field]}
                    onChange={handleInputChange}
                  />
                </div>
              ))}
            </div>
          </div>
          <br />
          <div className="col-md-12">
            <textarea
              name="message"
              placeholder={t('Message')}
              className="contact-textarea"
              value={formData.message}
              onChange={handleInputChange}
            />
          </div>
          <br />
          <div className="row">
            <div className="col-md-12">
              <input className="form-btn" type="submit" value={t('SendMessage')} />
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

export default Contact;
